import React from "react";
import Layout from "../components/Layout";
import "../scss/termsofuse.scss";
import { graphql } from "gatsby";
import {useTranslation } from 'gatsby-plugin-react-i18next';
import Priv from '../../static/files/Tyson-Online-Privacy-Notice-Supplement-Thailand-(03.10.22).pdf';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
    return (
        <Layout title="Privacy Policy">
            <div className="privacyContent">
                <div className="termsBg">
                    <h1>{t("privacyPolicy.title")}</h1>
                </div>
                <div className="privacy">
                    <div dangerouslySetInnerHTML={{ __html: [t("privacyPolicy.content1") + t("privacyPolicy.content2") + t("privacyPolicy.content3") + t("privacyPolicy.content4") + t("privacyPolicy.content5")] }}/>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer","privacyPolicy"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;